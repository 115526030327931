export default [
  {
    header: 'Ustawienia',
  },
  {
    title: 'Konfiguracja',
    route: 'pages-account-setting',
  },

  // {
  //   title: 'Konfiguracja',
  //   icon: 'CreditCardIcon',
  //   tag: 'new',
  //   // tagVariant: 'light-success',
  //   children: [
  //     {
  //       title: 'Faktur i dokumentów',
  //       route: 'card-basic',
  //     },
  //     {
  //       title: 'Opcji przeliczania walut',
  //       route: 'card-advance',
  //     },
  //     {
  //       title: 'Sposobów wyliczania',
  //       route: 'card-statistic',
  //     },
  //     {
  //       title: 'Słowników',
  //       route: 'card-analytic',
  //     },
  //     {
  //       title: 'Wydatki',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Produkty',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Cenniki',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Klienci',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Użytkownicy i uprawnienia',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Bezpieczeństwo',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Księgowość online',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Kasa',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Raporty',
  //       route: 'card-action',
  //     },
  //     {
  //       title: 'Płatności',
  //       route: 'card-action',
  //     },
  //   ],
  // },
  //   {
  //     title: 'Components',
  //     icon: 'ArchiveIcon',
  //     children: [
  //       {
  //         title: 'Alert',
  //         route: 'components-alert',
  //       },
  //       {
  //         title: 'Aspect',
  //         route: 'components-aspect',
  //       },
  //       {
  //         title: 'Avatar',
  //         route: 'components-avatar',
  //       },
  //       {
  //         title: 'Badge',
  //         route: 'components-badge',
  //       },
  //       {
  //         title: 'Breadcrumb',
  //         route: 'components-breadcrumb',
  //       },
  //       {
  //         title: 'Button',
  //         route: 'components-button',
  //       },
  //       {
  //         title: 'Button Group',
  //         route: 'components-button-group',
  //       },
  //       {
  //         title: 'Button Toolbar',
  //         route: 'components-button-toolbar',
  //       },
  //       {
  //         title: 'Calendar',
  //         route: 'components-calendar',
  //       },
  //       {
  //         title: 'Carousel',
  //         route: 'components-carousel',
  //       },
  //       {
  //         title: 'Collapse',
  //         route: 'components-collapse',
  //       },
  //       {
  //         title: 'Dropdown',
  //         route: 'components-dropdown',
  //       },
  //       {
  //         title: 'Embed',
  //         route: 'components-embed',
  //       },
  //       {
  //         title: 'Image',
  //         route: 'components-image',
  //       },
  //       {
  //         title: 'List Group',
  //         route: 'components-list-group',
  //       },
  //       {
  //         title: 'Media Objects',
  //         route: 'components-media',
  //       },
  //       {
  //         title: 'Modal',
  //         route: 'components-modal',
  //       },
  //       {
  //         title: 'Nav',
  //         route: 'components-nav',
  //       },
  //       {
  //         title: 'Overlay',
  //         route: 'components-overlay',
  //       },
  //       {
  //         title: 'Pagination',
  //         route: 'components-pagination',
  //       },
  //       {
  //         title: 'Pagination Nav',
  //         route: 'components-pagination-nav',
  //       },
  //       {
  //         title: 'Pill',
  //         route: 'components-pill',
  //       },
  //       {
  //         title: 'Pill Badge',
  //         route: 'components-pill-badge',
  //       },
  //       {
  //         title: 'Popover',
  //         route: 'components-popover',
  //       },
  //       {
  //         title: 'Progress',
  //         route: 'components-progress',
  //       },
  //       {
  //         title: 'Sidebar',
  //         route: 'components-sidebar',
  //       },
  //       {
  //         title: 'spinner',
  //         route: 'components-spinner',
  //       },
  //       {
  //         title: 'Tab',
  //         route: 'components-tab',
  //       },
  //       {
  //         title: 'Time',
  //         route: 'components-time',
  //       },
  //       {
  //         title: 'Timeline',
  //         route: 'components-timeline',
  //       },
  //       {
  //         title: 'Toasts',
  //         route: 'components-toasts',
  //       },
  //       {
  //         title: 'Tooltip',
  //         route: 'components-tooltip',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Extensions',
  //     icon: 'PlusCircleIcon',
  //     children: [
  //       {
  //         title: 'Sweet Alert',
  //         route: 'extensions-sweet-alert',
  //       },
  //       {
  //         title: 'Toastification',
  //         route: 'extensions-toastification',
  //       },
  //       {
  //         title: 'Slider',
  //         route: 'extensions-slider',
  //       },
  //       {
  //         title: 'Drag & Drop',
  //         route: 'extensions-drag-and-drop',
  //       },

  //       {
  //         title: 'Tour',
  //         route: 'extensions-tour',
  //       },

  //       {
  //         title: 'Clipboard',
  //         route: 'extensions-clipboard',
  //       },
  //       {
  //         title: 'Context Menu',
  //         route: 'extensions-context-menu',
  //       },

  //       {
  //         title: 'Swiper',
  //         route: 'extensions-swiper',
  //       },

  //       // {
  //       //   title: 'Tree',
  //       //   route: 'extensions-tree',
  //       // },

//       {
//         title: 'I18n',
//         route: 'extensions-i18n',
//       },
//     ],
//   },
//   {
//     title: 'Page Layouts',
//     icon: 'LayoutIcon',
//     children: [
//       {
//         title: 'Collapsed Menu',
//         route: 'page-layout-collapsed-menu',
//       },
//       {
//         title: 'Layout Boxed',
//         route: 'page-layout-boxed-layout',
//       },
//       {
//         title: 'Without Menu',
//         route: 'page-layout-without-menu',
//       },
//       {
//         title: 'Layout Empty',
//         route: 'page-layout-layout-empty',
//       },
//       {
//         title: 'Layout Blank',
//         route: 'page-layout-layout-blank',
//       },
//     ],
//   },
]
