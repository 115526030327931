export default [
  {
    header: 'Moduły',
  },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  {
    title: 'Faktury',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Wszystkie faktury',
        route: 'apps-invoice-list',
      },
      // {
      //   title: 'Wystaw fakture',
      //   route: { name: 'apps-invoice-add' },
      // },

      {
        title: 'Katalog kontrahentów',
        route: 'apps-contractors-list',
      },
      {
        title: 'Zestawienia i raporty',
        route: 'invoice-reports',
      },
      {
        title: 'Windykacja',
        route: 'apps-invoices-expired',
      },
      // {
      //   title: 'Preview',
      //   route: { name: 'apps-invoice-preview', params: { id: 4987 } },
      // },
      // {
      //   title: 'Edit',
      //   route: { name: 'apps-invoice-edit', params: { id: 4987 } },
      // },

    ],
  },
  // {
  //   title: 'eCommerce',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Shop',
  //       route: 'apps-e-commerce-shop',
  //     },
  //     {
  //       title: 'Details',
  //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
  //     },
  //     {
  //       title: 'Wishlist',
  //       route: 'apps-e-commerce-wishlist',
  //     },
  //     {
  //       title: 'Checkout',
  //       route: 'apps-e-commerce-checkout',
  //     },
  //   ],
  // },

  {
    title: 'Segregator',
    icon: 'UserIcon',
    children: [
      {
        title: 'Dokumenty kosztowe',
        route: 'documents-costs',
      },
      {
        title: 'Dokumenty sprzedażowe',
        route: 'documents-sale',
      },
      {
        title: 'Listy płac',
        route: 'documents-payroll',
      },
      {
        title: 'Deklaracje podatkowe',
        route: 'documents-tax-returns',
      },
      {
        title: 'Wyciągi bankowe',
        route: 'documents-bank-statement',
      },
    ],
  },
  {
    title: 'Pracownicy',
    icon: 'UserIcon',
    children: [
      {
        title: 'Lista pracowników',
        route: 'apps-workers-list',
      },
      // {
      //   title: 'Limity urlopowe',
      //   route: 'apps-users-holidays-limits',
      // },
      {
        title: 'Kalendarz urlopów',
        route: 'apps-calendar',
      },
      // {
      //   title: 'View',
      //   route: { name: 'apps-users-view', params: { id: 21 } },
      // },
      // {
      //   title: 'Edit',
      //   route: { name: 'apps-users-edit', params: { id: 21 } },
      // },
    ],
  },
  {
    title: 'Raporty i zestawienia',
    icon: 'UserIcon',
    children: [
      {
        title: 'Wystawione faktury',
        route: 'reports-invoices',
      },
      {
        title: 'Przychody',
        route: 'reports-income',
      },
      {
        title: 'Koszty',
        route: 'reports-costs',
      },
    ],
  },
  {
    title: 'Rozliczenie z biurem rachunkowym',
    icon: 'UserIcon',
    action: 'all',
    resource: 'Dashboard',
    // children: [
    //   {
    //     title: 'Limity urlopowe',
    //     route: '#',
    //   },
    // ],
  },
  {
    header: 'Klienci biura rachunkowego',
    meta: {
      allowed: 'tax-office',
    },
  },
  {
    title: 'Obsługiwani klienci',
    icon: 'UserIcon',
    route: 'tax-office-client-list',
    meta: {
      allowed: 'tax-office',
    },
  },
  {
    header: 'Klienci WebAPP',
    meta: {
      allowed: 'admin',
    },
  },
  {
    title: 'Lista biur',
    icon: 'UserIcon',
    route: 'web-app-client-list',
    meta: {
      allowed: 'admin',
    },
  },
  // {
  //   title: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Authentication',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Login v1',
  //           route: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login v2',
  //           route: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v1',
  //           route: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v2',
  //           route: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v1',
  //           route: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v2',
  //           route: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v1',
  //           route: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v2',
  //           route: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Account Settings',
  //       route: 'pages-account-setting',
  //     },
  //     {
  //       title: 'Profile',
  //       route: 'pages-profile',
  //     },
  //     {
  //       title: 'Faq',
  //       route: 'pages-faq',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'pages-knowledge-base',
  //     },
  //     {
  //       title: 'Pricing',
  //       route: 'pages-pricing',
  //     },
  //     {
  //       title: 'Blog',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'pages-blog-list',
  //         },
  //         {
  //           title: 'Detail',
  //           route: { name: 'pages-blog-detail', params: { id: 1 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'pages-blog-edit', params: { id: 1 } },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Mail Templates',
  //       children: [
  //         {
  //           title: 'Welcome',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //         },
  //         {
  //           title: 'Reset Password',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //         },
  //         {
  //           title: 'Verify Email',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //         },
  //         {
  //           title: 'Deactivate Account',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //         },
  //         {
  //           title: 'Invoice',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //         },
  //         {
  //           title: 'Promotional',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Coming Soon',
  //           route: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Not Authorized',
  //           route: 'misc-not-authorized',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Under Maintenance',
  //           route: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           route: 'misc-error',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //   ],
  // },
]
